$( document ).ready(function() {

    $(".js-panel-link").on( "click", function(event) {
        let nameCountry = $(this).data("country");

        $(".js-panel-link").removeClass("is-active");
        $(this).addClass("is-active");

        if (nameCountry == "all") {
            $(".js-planet-item").removeClass("is-hidden").removeClass("is-active");
        } else {
            $(".js-planet-item").addClass("is-hidden").removeClass("is-active");
            $(".js-planet-item[data-country="+nameCountry+"]").addClass("is-active");
        }
    });
    

    function fixedPanel(scroll) {
        let heightPanel = $(".js-panel").outerHeight(),
            heightWindow = $(window).height();


        $(".js-panel-block").height(heightPanel);

        if (scroll > (heightPanel - heightWindow )) {
            $(".js-panel").addClass("is-active");
        } else {
            $(".js-panel").removeClass("is-active");
        }
    }

    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();


        fixedPanel(scrollTop);
    });
    

    /*** INDEX ANIMATION ***/

    

   /*gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            start: "top top", 
            pin: true, 
            pinSpacing: false 
        });
    });*/
 /*
    ScrollTrigger.create({
        snap: 1 / 4 
    });*/

    /*** END INDEX ANIMATION ***/


    /*** MENU ADAPTIVE ***/

    $(".js-hamburger").on( "click", function(event) {
        $(".js-menu-adaptive").toggleClass("is-open");
        $(".js-hamburger").toggleClass("is-active");
    });

    $(".js-menu-adaptive").on( "click", function(event) {
        if( $(event.target).hasClass('js-menu-adaptive')) {
            $(".js-menu-adaptive").removeClass("is-open");
        }
    });

    $(".js-menu-adaptive-close").on( "click", function(event) {
        $(".js-menu-adaptive").removeClass("is-open");
    });

  /* $(".js-menu-adaptive").swipe({
        swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
            console.log(direction)
            if (direction == "right") {
                $(".js-menu-adaptive").removeClass("is-open");
            }
        }
    });

    $(".js-menu-adaptive .menu-adaptive__wrap").swipe({
        swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
            console.log(direction)
            if (direction == "right") {
                $(".js-menu-adaptive").removeClass("is-open");
            }
        }
    });*/

    /*** END MENU ADAPTIVE ***/
    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();

        $(".js-anchor-block").each(function( index , elem ) {
            let offsetItem = $(elem).offset().top,
                dataItem = $(this).data("anchor");


            if ((offsetItem - 120) < scrollTop) {
                $(".js-anchor-item").removeClass("is-active");
                $(".js-anchor-item[data-anchor="+dataItem+"]").addClass("is-active");
            }
        });
    });
    


    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();

        $(".js-about-item").each(function( index , elem ) {
            let offsetItem = $(elem).offset().top,
                heightItem = $(elem).height();

            console.log("offsetItem" , offsetItem);
            if ((offsetItem - (heightItem / 2)) < scrollTop) {
                $(elem).addClass("is-active");
            }
        });
    });

    $(".js-phone").mask("+7(999)999-99-99");

    $(".js-name").on( "keypress", function() {
        var that = this;
        setTimeout(function() {
          var res = /[^a-zA-Zа-яА-ЯїЇєЄіІёЁ ]/g.exec(that.value);
          that.value = that.value.replace(res, '');
        }, 0);
    });

    if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [47.283682, 39.857072],
                zoom: 16
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.png',
                iconImageSize: [62, 93],
                iconImageOffset: [-31, -93]
            });


            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }

    $(window).scroll(function() {
        if ($(".js-card-mobile").length) {
            let scrollTop = $(window).scrollTop(),
            heightCardMobile = $(".js-card-mobile").outerHeight(),
            topCardMobile = $(".js-card-mobile").offset().top;
    
            if (scrollTop > (heightCardMobile + topCardMobile)) {
                $(".js-card-fixed").addClass("is-active");
            } else {
                $(".js-card-fixed").removeClass("is-active");
            }
        }
       
    });

    $(".js-search-open").on( "click", function() {
        $(".js-search").addClass("is-open")
    });

    $(".js-search-close").on( "click", function() {
        $(".js-search").removeClass("is-open")
    });


    $(".js-share-btn").on( "click", function() {
        $(".js-share").toggleClass("is-open");
        return false;
    });

    $(".js-share-close").on( "click", function() {
        $(".js-share").removeClass("is-open");
    });

    if ($(window).width() < 640) {
        $(".js-tag-top").on( "click", function() {
            $(this).toggleClass("is-active");
            $(this).next(".js-tag-list").slideToggle(300, function() {

            });
        });
    }

    if ($(window).width() < 961) {
        $('.js-gallery').slick({
            dots: true,
            arrows: false
        });

        $('.js-article-slider').slick({
            dots: true,
            arrows: false
        });

        $(".js-card-top").on("click" , function(){
            $(this).toggleClass("is-active");
            $(this).next().slideToggle( 300 , function() { });
            $('.js-gallery').slick('destroy').slick({
                dots: true,
                arrows: false
            });
            $('.js-article-slider').slick('destroy').slick({
                dots: true,
                arrows: false
            });
        });
    }


    

    $('.js-grid').masonry({
        // options
        itemSelector: '.js-grid-item'
    });

    /*** SCHEMA MAIN ***/
        $(".js-schema-item").on("mouseover" , function(){
            let typeItem = $(this).data("type");

            $(".js-schema-item[data-type="+typeItem+"]").addClass("is-active");
            $(".js-scheme-line[data-type="+typeItem+"]").addClass("is-active");

            if ((typeItem == "antenna") || (typeItem == "mobile") || (typeItem == "management") || (typeItem == "steering")  || (typeItem == "main")) {
                $(".js-scheme-line-all").addClass("is-active");
            }
        })

        $(".js-schema-item").on("mouseout" , function(){
            $(".js-schema-item").removeClass("is-active");
            $(".js-scheme-line").removeClass("is-active");
            $(".js-scheme-line-all").removeClass("is-active");
        });

        $(".js-schema-item").on("click" , function(){
            let typeItem = $(this).data("type");
            $(".js-popup-schema[data-type="+typeItem+"]").addClass("is-open");
        });

        $(".js-popup-schema-close").on("click" , function(){
            $(".js-popup-schema").removeClass("is-open");
        });
    /*** END SCHEMA MAIN ***/
    

    $( window ).scroll(function() {
        if ($(this).scrollTop() >= 120) {
            $(".header").addClass("is-fixed");
        } else {
            $(".header").removeClass("is-fixed");
        }
    });

    $(".js-card-aside").stick_in_parent({
        offset_top: 100
    });

    $(".js-sticky").stick_in_parent({
        offset_top: 100
    });

    if ($(window).width() > 960) {
        let heightWindow =  $(window).height(),
            blockHeight = $(".js-history-main").height(),
            offsetBlock = (heightWindow / 2) - (blockHeight / 2);

        console.log("heightWindow =" , heightWindow);

        $(".js-history").stick_in_parent({
            offset_top: offsetBlock
        });
    }

    $('.js-anchor-item').on("click" , function(){

        let anchor = $(this).data('anchor');  

        $('html, body').animate({           
            scrollTop:   $('.js-anchor-block[data-anchor='+anchor+']').offset().top - 100 
        }, 600);     

    });

    /*** POPUP ***/
    $(".js-popup-open").on("click" , function(){ 
        let popup = $(this).data("popup");

        $("body").addClass("is-hidden");
        $(".js-popup[data-popup="+popup+"]").addClass("is-open");
        console.log("sdfsdfsdfsdf");
        return false;
    });


    $(document).on("click" , function(e){ 
        var popup = $(".js-popup");
        if (popup.is(e.target)) {
          $(popup).removeClass("is-open");
          $("body").removeClass("is-hidden");
        }
      });
    

    $(".js-popup-close").on("click" , function(){ 
        $("body").removeClass("is-hidden");
        $(this).parents(".js-popup").removeClass("is-open");
    });
    /*** END POPUP ***/

    $(".js-tab-item").on("click" , function(){ 
        let tabNumber = $(this).data("tab");

        $(".js-tab-item").removeClass("is-active");
        $(this).addClass("is-active");

        $(".js-tab-block").removeClass("is-active");
        $(".js-tab-block[data-tab="+tabNumber+"]").addClass("is-active");
    });

    $(".js-submit").on("click" , function(){ 
        $(".js-submit").parents(".form-big").addClass("is-ok");
        return false;
    });

    /*** SLIDER ***/

    $('.js-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg width='11' height='20' viewBox='0 0 11 20' class='svg-icon'><use xlink:href='#svg-arrow-slider-detail'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='11' height='20' viewBox='0 0 11 20' class='svg-icon'><use xlink:href='#svg-arrow-slider-detail'></use></svg>\
        </button>",
        asNavFor: '.js-slider-nav',
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $('.js-slider-nav').slick({
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.js-slider-for',
        focusOnSelect: true
    });

    $(".js-schema-mobile-slider").slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear',
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg width='6' height='10' viewBox='0 0 6 10' class='svg-icon'><use xlink:href='#svg-arrow-slider'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='6' height='10' viewBox='0 0 6 10' class='svg-icon'><use xlink:href='#svg-arrow-slider'></use></svg>\
        </button>"
    });

    $(".js-news-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 961,
            settings: {
                arrows: false,
              slidesToShow: 1,
              centerMode: true,
              variableWidth: true
            }
          }
        ]
    });

    /*** END SLIDER ***/


    $(".js-select").styler();
});